import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../../Shared/Components/ModalBox';
import ConfirmationPopup from '../../../Shared/Components/ConfirmationPopup';

function OrderSummary({ selectedOrders }) {
  const { t } = useTranslation();
  const [alertPopup, setAlertPopup] = useState('');

  const handleConfirmRemove = () => {
    setAlertPopup('close');
  };

  return (
    <div className="license-summary-wrap">
      <ModalBox
        status={alertPopup === 'open'}
        setOpen={setAlertPopup}
        closeModal={() => { }}
        modalView={{
          content: <ConfirmationPopup
            title="Are you sure you want to remove this Item?"
            description={<>This will remove the item from the summary. <br />You cannot undo this action.</>}
            onCloseFunction={() => setAlertPopup('close')}
            closeButtonLabel="Back"
            onConfirmFunction={handleConfirmRemove}
            confirmButtonLabel="Confirm"
            confirmButtonFontColor="#FFF"
            confirmButtonColor="#E8212E"
            confirmButtonHoverColor="#E8212E"
          />,
        }}
        notification
      />
      <div className="license-summary-container">
        <div className="license-summary">
          <div className="license-content">
            <h3>{t('licenseId')}</h3>
            <p>{selectedOrders?.license_id}</p>
            <h3>{t('licenseType')}</h3>
            <p>{t(selectedOrders?.license_type)}</p>
            <h3>{t('planName')}</h3>
            <p>{t(selectedOrders?.plan_name)}</p>
            <h3>{t('tenure')}</h3>
            <p>{selectedOrders?.tenure}</p>
            <h3>{t('totalDisplay')}</h3>
            <p>{selectedOrders?.device_count}</p>
            <h3>{t('price')}</h3>
            <p>{selectedOrders?.license_price}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

OrderSummary.propTypes = {
  selectedOrders: PropTypes.objectOf().isRequired,
};

export default OrderSummary;
