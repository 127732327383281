/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import licensesApi from '../../utils/api/licenses';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat } from '../../pages/Global/timeFormat';

export const fetchLicenses = createAsyncThunk(
  'licenses/fetchLicenses',
  async (data, { dispatch }) => {
    const response = await licensesApi.getLicenses(data)
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          company_name: item?.company?.name,
          created_at: changeDateTimeFormat(item?.createdAt),
          license_plan_name: item?.license_plan?.name,
          id: item?._id,
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

export const fetchAccountListAll = createAsyncThunk(
  'licenses/fetchAccountListAll',
  async (data, { dispatch }) => {
    const response = await licensesApi.getAccountAll()
      .then((response1) => response1?.data.map((val) => ({
        ...val,
        id: val._id,
        label: val.name,
      })))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  licenses: [],
  accountAll: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: '',
  filter: {},
  tableFilterData: {},
};

export const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addLicensesDetails: (state, action) => {
      state.licenses.unshift({
        ...action.payload,
        storage: `${action?.payload?.storage_limit?.storage || 0}
        ${action?.payload?.storage_limit?.unit === 'gb' ? 'GB' : 'MB'}`,
        id: action?.payload?._id,
      });
    },
    resetLicensesState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLicenses.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = (action.payload.page + 1);
          state.totalPageCount = action?.payload?.totalPageCount === 0 ? 1 : action?.payload?.totalPageCount;
          if (action?.payload?.page === 1) {
            state.licenses = data;
          } else {
            const finalData = [...state.licenses];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.licenses = finalData;
          }
        }
      });
    builder.addCase(fetchAccountListAll.fulfilled, (state, action) => {
      // Add user to the state array
      state.accountAll = action.payload;
    });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetLicensesState,
  addLicensesDetails,
} = licensesSlice.actions;

export default licensesSlice.reducer;
