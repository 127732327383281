import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../../Shared/Components/TopContent';
import OrderSummary from './OrderSummary';
import Button from '../../../Shared/Components/Button';
import { RadioButton } from '../../../Shared/Components/Form/Input';
import { setSelectedOrders } from '../../../redux/slices/LicensesPendingSlice';

function PaymentSummary() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrders } = useSelector((state) => state.licensesPending);
  const navigateBack = () => {
    dispatch(setSelectedOrders({}));
    navigate(-1);
  };

  const submit = () => {
    navigate('../info');
  };

  useEffect(() => {
    if (Object.keys(selectedOrders).length === 0) {
      navigate('/super-admin/license-transaction/pending');
    }
  }, []);
  return (
    <div className="main-container">
      <TopContent
        label={[
          t('transactions'),
          selectedOrders?.company?.name || selectedOrders?.companyName,
        ]}
        labelClickFunc={() => navigate(-1)}
        customLabelClickFunc
      />
      <div className="content-area">
        <div className="product-summary-wrap">
          <div className="product-summary">
            <h2 className="summary-title">{t('licenseSummary')}</h2>
            <OrderSummary selectedOrders={selectedOrders} />
            <div className="form-button-group">
              <div className="form-button">
                <Button
                  label={t('back')}
                  click={navigateBack}
                  classes="default-button"
                />
              </div>
              <div className="form-button">
                <Button
                  label={t('continue')}
                  click={submit}
                  classes="success-button"
                />
              </div>
            </div>
          </div>
          <div className="price-summary">
            <h2 className="summary-title">{t('transactionSummary')}</h2>
            <div className="price-details">
              <div className="single-detail">
                <span>
                  {t('orderAmount')}
                </span>
                <span>
                  {selectedOrders.license_price}
                </span>
              </div>
              <div className="single-detail">
                <span>
                  {t('receivedAmount')}
                </span>
                <span>
                  {selectedOrders.license_price - selectedOrders.pending_payment}
                </span>
              </div>
              <div className="single-detail">
                <span>
                  {t('remainingAmount')}
                </span>
                <span>
                  {selectedOrders.pending_payment}
                </span>
              </div>
              <div style={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#1F1F1F',
                borderBottom: '1px solid #CACACA',
              }}
              >
                <span>
                  {t('discountForOrderAmount')}
                </span>
                <div style={{ display: 'flex', gap: '50px', padding: '15px' }}>
                  <RadioButton
                    // checked={discountType === 'percentage'}
                    label="%"
                    value="percentage"
                    onChange={() => {}}
                  />
                  <RadioButton
                    // checked={discountType === 'fixed'}
                    label="$"
                    value="fixed"
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="single-detail">
                <span>
                  {t('totalAmount')}
                </span>
                <span>
                  {selectedOrders.license_price}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSummary;
