import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TopContent from '../../Shared/Components/TopContent';
import { checkPermissionAllow } from '../../utils/helpers';

function Transaction() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locate = useLocation();
  const currentPath = locate.pathname;
  const parts = currentPath.split('/');

  const menus = [
    {
      name: t('Paid'),
      url: 'summary',
      enable: checkPermissionAllow('getSummaryTransaction'),
    },
    {
      name: t('Balance'),
      url: 'pending',
      enable: checkPermissionAllow('getSaasPendingTransaction'),
    },
    {
      name: t('Transaction Summary'),
      url: 'transactionSummary',
      enable: checkPermissionAllow('getSaasPendingTransaction'),
    },
  ];

  const handleMenuChange = (url) => {
    navigate(`./${url}`);
  };

  return (
    <div className="main-container">
      <TopContent label={t('transactions')} />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="subscription-menu">
              {
                menus?.map((menu) => menu.enable && (
                  <div
                    role="presentation"
                    className={parts[parts.length - 1] === menu.url ? 'each-menu menu-active' : 'each-menu'}
                    onClick={() => handleMenuChange(menu.url)}
                  >
                    {menu.name}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default Transaction;
