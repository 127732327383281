import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import Button from '../../../Shared/Components/Button';
import { checkPermissionAllow } from '../../../utils/helpers';

function NewContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onButtonClick = (account) => navigate(`../company-add/${account}`);

  return (
    <div className="new-content">
      <div className="content-buttons">
        <div className="buttons-list">
          {
            checkPermissionAllow('addCmsCompany') && (
              <Button
                label={t('cmsAccount')}
                click={() => onButtonClick('cms')}
                classes="default-button button-outline-success"
              />
            )
          }
          {
            checkPermissionAllow('addDistributorCompany') && checkPermissionAllow('addCmsCompany') && (
              <div className="or-break">
                <span className="hr-line"> </span>
                <div className="hr-text">{t('or')}</div>
                <span className="hr-line"> </span>
              </div>
            )
          }
          {
            checkPermissionAllow('addDistributorCompany') && (
              <Button
                label={t('cmsServer')}
                click={() => onButtonClick('cmsServer')}
                classes="default-button button-outline-success"
              />
            )
          }
          {
            checkPermissionAllow('addDistributorCompany') && checkPermissionAllow('addCmsCompany') && (
              <div className="or-break">
                <span className="hr-line"> </span>
                <div className="hr-text">{t('or')}</div>
                <span className="hr-line"> </span>
              </div>
            )
          }
          {
            checkPermissionAllow('addDistributorCompany') && (
              <Button
                label={t('distributorAccount')}
                click={() => onButtonClick('distributor')}
                classes="default-button button-outline-success"
              />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default NewContent;
