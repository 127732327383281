/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import more from '../../../assets/icons/more.png';

import Table from '../../Table';
import {
  fetchLicensesPending,
  setFilter,
  setSelectedOrders,
  setTableFilterData,
} from '../../../redux/slices/LicensesPendingSlice';
import DropDown from '../../../Shared/Components/DropDown';
import MultiSelectPopup from './SelectPopup';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function LicensesPending() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const statusList = [
    { value: 'pending', label: t('pending') },
    { value: 'payment_updated', label: t('paymentUpdated') },
  ];

  const {
    licensesPending,
    status,
    pageCount,
    totalPageCount,
    totalDataCount,
    filter,
    tableFilterData,
  } = useSelector((state) => state.licensesPending);

  const [popupOpen, setPopupOpen] = useState(false);
  const [successNotification, setSuccessNotification] = useState('');

  // Table state variables
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const updateSinglePayment = (id) => {
    const selectedOrders = licensesPending.find((data) => data.id === id);
    dispatch(setSelectedOrders({
      ...selectedOrders,
    }));
    navigate('../../license-payment/summary');
  };

  const actions = [
    {
      name: t('updatePayment'),
      url: '',
      function: updateSinglePayment,
      key: 'update',
      updatePayment: true,
      enable: checkPermissionAllow('updatePaymentTransaction'),
    },
  ];

  const columns = [
    // {
    //   header: t('sno'),
    //   accessorKey: 'sno',
    // },
    {
      header: t('licenseType'),
      accessorKey: 'license_type',
      Cell: ({ row }) => t(row.original.license_type),
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
      filterFn: 'commonFilterFn',
    },
    {
      header: t('planName'),
      accessorKey: 'plan_name',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => t(row.original.plan_name),
    },
    {
      header: t('totalAmount'),
      accessorKey: 'license_price',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('remainingAmount'),
      accessorKey: 'pending_payment',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('tenure'),
      accessorKey: 'tenure',
    },
    {
      header: t('paymentStatus'),
      accessorKey: 'status',
      filterVariant: 'select',
      filterSelectOptions: statusList,
      Cell: ({ row }) => {
        const originalStatus = row.original.full_payment_status;
        let paymentStatus = '';

        if (originalStatus === 'pending') {
          paymentStatus = <span className="payment-pending">{t('pending')}</span>;
        } else if (originalStatus === 'payment_updated') {
          paymentStatus = <span className="payment-updated">{t('updated')}</span>;
        }

        return paymentStatus;
      },
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const getLicensesPendingData = (page, filters) => {
    dispatch(fetchLicensesPending({ page, ...filters }));
  };

  // Function to fetch next page data, scroll inside table
  const handleScroll = () => {
    getLicensesPendingData(pageCount + 1, filter);
  };

  // Function to update payment
  const updatePayment = () => {
    const selectedOrders = licensesPending.filter((data) => Object.keys(rowSelection).includes(data.id));
    dispatch(setSelectedOrders({ selectedOrders, updatePaymentType: 'licenses-pending' }));

    let navigateToPayment = true;
    const firstSelectedCompanyId = selectedOrders?.[0]?.company?._id;

    selectedOrders.forEach((order) => {
      if (
        order?.company?._id !== firstSelectedCompanyId
        || order?.status !== 'pending'
      ) {
        dispatch(setErrorNotification({
          message: 'Selected data are not from the same company or payment has already been updated.',
        }));
        navigateToPayment = false;
      }
    });

    if (navigateToPayment) {
      setRowSelection({});
      navigate('../../license-payment/summary');
    }
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter({ ...filteredValues, status: 'pending' }));
    getLicensesPendingData(1, { ...filteredValues, status: 'pending' });
  };
  useEffect(() => {
    setIsFirstRender(false);
    getLicensesPendingData(1, { status: 'pending' });
  }, []);

  useEffect(() => {
    setPopupOpen(true);
  }, [rowSelection]);

  return (
    <div>
      <ModalBox
        status={successNotification === 'open'}
        closeModal={() => setSuccessNotification('close')}
        modalView={{ content: <Success message={t('paymentVerifiedSuccess')} /> }}
        notification
      />
      <MultiSelectPopup
        openPopup={Object.keys(rowSelection)?.length > 0 && popupOpen}
        setPopupOpen={setPopupOpen}
        rowSelection={Object.keys(rowSelection)}
        setRowSelection={setRowSelection}
        updatePayment={updatePayment}
        closePopup={() => setPopupOpen(false)}
      />
      <div className="table-container">
        <Table
          header={columns}
          value={licensesPending}
          status={status}
          totalPageCount={totalPageCount}
          pageCount={pageCount}
          scrollData={handleScroll}
          totalDataCount={totalDataCount}
          reduxColumnFiltersSorting={tableFilterData}
          onFilterOrSortingChange={onFilterOrSortingChange}
          isFirstRender={isFirstRender}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          enable={tableFilterData?.columnFilters?.length > 0}
          height={height.tableHeight}
        />
      </div>
    </div>
  );
}

LicensesPending.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      company_name: PropTypes.string.isRequired,
      permissions: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

LicensesPending.defaultProps = {
  row: null,
};

export default LicensesPending;
